import React from "react";
import { Route, Switch } from "react-router-dom";
// import Emagreca from "./pages/Emagreca";
import Home from "./pages/Home";

const Router = () => {
  return (
    <Switch>
        {/* <Route path="/emagreca">
          <Emagreca />
        </Route> */}
        <Route path="/" exact>
          <Home />
        </Route>
    </Switch>
  );
}

export default Router;